<template>
  <v-app>
    <v-card class="bgs rounded-0 d-flex justify-center" flat width="100%" height="100%">
      <v-container>
        <h2 class="text-center mt-6 font24 mb-4">
          {{ $t("Taoify用户隐私政策") }}
        </h2>
        <p class="content mt-4">
          {{ $t("我们致力于保护您在使用我们网站时所提供的私隐、私人资料以及个人的资料（统称 “个人资料”）使我们在收集、使用、储存和传送个人资料方面符合（与个人资料私隐有关的法律法规）及消费者保护方面的最高标准。") }}
        </p>
        <p class="content">
          {{ $t("为确保您对我们在处理个人资料上有充分信心, 您切要详细阅读及理解隐私政策的条文。特别是您一旦使用我们的网站, 将被视为接受、同意、承诺和确认：") }}
        </p>
        <p class="content">
          {{ $t("您在自愿下连同所需的同意向我们披露个人资料；您会遵守本隐私政策的全部条款和限制；您在我们的网站上作登记、资料会被收集；您同意日后我们对隐私政策的任何修改；您同意我们的分公司、附属公司、雇员、就您可能会感兴趣的产品和服务与您联络。") }}
        </p>
        <p class="content">
          {{ $t("我们会根据 Taoify 运营、法律或合规需求不定时的修订本《隐私政策》的条款。如该修订对《隐私政策》产生实质改变，我们将在 Taoify 网站公示此修订的政策或以其他适当的方式通知您。在修订公示以后，您对 Taoify 网站和服务的继续使用即视为同意接受经修订的《隐私政策》。") }}
        </p>

        <h3 class="titlee">{{ $t("一、适用范围") }}</h3>
        <p class="content">
          {{ $t("本隐私权政策适用于平台提供的所有服务，您访问平台网站及/或登陆相关客户端使用平台提供的服务，均适用本隐私权政策。需要特别说明的是，本隐私权政策不适用于其他第三方向您提供的服务，例如上的卖家依托平台向您提供服务时，您向卖家提供的个人信息不适用于本隐私权政策。") }}
        </p>

        <h3 class="titlee">{{ $t("二、个人信息的收集") }}</h3>
        <ul>
          <li>{{ $t("1. 在您注册我们的服务、获取使用我们的服务或者以其他方式向我们提供信息时，我们会收集您的个人信息。") }}</li>
          <li>{{ $t("2. 经您同意，我们会收集、管理和监控个人资料。为了向您提供我们的各项服，您需要提供那些我们认为为达到你的指示和进一步改善我们的服务所需的个人资料和不具名的资料，包括但不限于：姓名、公司名称、地址、电子邮件地址、电话号码（手机或座机号）以及信用卡信息。") }}</li>
          <li>{{ $t("3. 我们收集您访问托管在 Taoify 上的网页的相关数据。我们还会收集有关您访问账号的方式和时间的相关数据，包括您使用的设备和浏览器，网络连接以及您的IP地址等信息。我们需要这些信息来帮助您访问并改进我们的服务。") }}</li>
          <li>{{ $t("4. 我们需要这些信息为商家提供服务，包括支付和处理订单、验证和处理付款。同时，我们通过这些信息来改善我们的服务。我们用这些信息为您提供我们的服务，以便您能够处理订单并更好地为您的客户提供服务。") }}</li>
        </ul>

        <h3 class="titlee">{{ $t("三、个人信息的用途") }}</h3>
        <p class="content">
          {{ $t("通常情况，本网站将采取合理的措施保护您的个人信息，使其避免未经授权的披露。在遵守相关法律的前提下，我们可能会与以下实体（可能包括境内和境外）分享使用您的个人信息。") }}
        </p>
        <ul>
          <li>{{ $t("1. 供应商：包括但不限于为了满足您网站需求的供货商、软件商、结汇收款、仓储物流、活动展会服务、代理商等。这些供应商可能根据需要与您联系，以完成相关产品服务；") }}</li>
          <li>{{ $t("2. 金融机构和第三方支付机构：当您预订订单、申请退款、购买保险时，我们会与金融机构或第三方支付机构共享特定的订单信息，当我们认为用于欺诈检测和预防目的实属必要时，我们将进一步和相关金融机构共享其他必要信息，如IP地址等；") }}</li>
          <li>{{ $t("3. 业务合作伙伴：我们可能与合作伙伴一起为您提供产品或者服务，包括快递业务、通讯服务、客户服务、市场推广、广告投放等；") }}</li>
          <li>{{ $t("4. 我们可能会将来自某项服务的个人信息与来自其他服务的信息结合起来，用于为了给您提供更加个性化的服务使用，例如让您拥有更广泛的社交圈的需要而使用；") }}</li>
          <li>{{ $t("5. 经您许可的其他用途。") }}</li>
        </ul>

        <h3 class="titlee">{{ $t("四、Cookie的使用") }}</h3>
        <p class="content">
          {{ $t("1. 我们将通过您的IP地址来收集您的部分非个人化信息，例如您的浏览器性质、操作系统种类、给您提供接入服务的ISP的域名等，以优化在您计算机屏幕上显示的页面。通过收集该信息，我们将进行客流量统计，从而改进网站的管理和服务。") }}
        </p>
        <p class="content">
          {{ $t("2. Cookies是一种技术，当您访问设有Cookies装置的本网站时，本网站之服务器会自动发送Cookies至您浏览器内，并储存到您的电脑硬盘内，此Cookies便负责记录日后您到访本网站的种种活动、个人资料、浏览习惯、消费习惯甚至信用记录。") }}
        </p>
        <p class="content">
          {{ $t("运用Cookies技术，本网站能够为您提供更加周到的个性化服务。本网站将会运用Cookies追访您的购物喜好，从而向您提供感兴趣的信息资料或储存密码，以便您造访本网站时不必每次重复输入密码。") }}
        </p>

        <h3 class="titlee">{{ $t("五、个人信息的管理") }}</h3>
        <p class="content">
          {{ $t("如果您是使用 Taoify 服务的商家，我们不会使用我们从您或您的客户处收集的个人信息单独联系或向您的客户营销。但是如果我们是从其他渠道获取到您客户的信息，比如客户自己提供给我们， Taoify 可能会联系或向这些客户营销。") }}
        </p>
        <p class="content">
          {{ $t("我们实施妥适的实物、电子、管理及技术方面的措施来保护和保障您的个人资料的安全。我们尽力确保通过我们网站所搜集的任何个人资料皆免于任何与我们无关的第三者的滋扰。在我们网站上所搜集的一切资料都由我们所拥有，不会出租或出售给任何无关的第三方。") }}
        </p>
        <p class="content">
          {{ $t("尽管实施了上述技术和保安的措施，我们不能保证资料在互联网上的输送绝对安全，因此我们不能绝对保证您通过我们网站提供给我们的个人资料在一切时候都是安全的。对任何因未经授权而接触您个人资料所发生的事件我们一槪不承担责任，于这方面产生或导致的任何损失或损害，我们也不负责赔偿。") }}
        </p>
        <p class="content">
          {{ $t("除了我们致力确保您的个人资料存放和处理的安全外，您不应向任何人披露您的登录密码或帐户资料，以保护您的个人资料。每当您登录我们网站时，尤其是当您使用他人的电脑或者是公共的互联网终端机时，请记着操作完毕后一定要点击退出。您的努力和协助对于我们保护您的个人资料绝对有帮助。") }}
        </p>
        <p class="content">
          {{ $t("您保留个人信息的所有权利，并且可以随时访问。此外， Taoify 会采取合理的措施，允许您更正、修改、删除或限制您个人信息的使用。您可以在账户设置中直接更新许多类型的个人信息，例如，支付和联系信息。如果您无法在您的账户设置中更改您的个人信息，请联系我们进行必要的更改。请务必记住，如果您删除或限制我们使用您的个人信息，有一些服务可能会无法正常运行。") }}
        </p>

        <h3 class="titlee">{{ $t("六、我们如何共享信息") }}</h3>
        <p class="content">
          {{ $t("我们对您的信息承担保密义务，不会为满足第三方的营销目的而向其出售或出租您的任何信息，我们会在下列情况下才将您的信息与第三方共享：") }}
        </p>
        <ul>
          <li>{{ $t("1. 事先获得您的同意或授权；") }}</li>
          <li>{{ $t("2. 根据法律法规的规定或行政或司法机构的要求；") }}</li>
          <li>{{ $t("3. 向关联方分享您的个人信息；") }}</li>
          <li>{{ $t("4. 向可信赖的合作伙伴提供您的个人信息，让他们根据我们的指示并遵循我们的隐私权政策以及其他任何相应的保密和安全措施来为我们处理这些信息；") }}</li>
          <li>{{ $t("5. 如您出现违反中国有关法律、法规或者相关协议或相关规则的情况；") }}</li>
          <li>{{ $t("6. 为维护及其关联方或用户的合法权益。") }}</li>
        </ul>

        <h3 class="titlee">{{ $t("七、修订与通知") }}</h3>
        <p class="content">
          {{ $t("1. 为给您提供更好的服务以及随着 Taoify 业务的发展，本隐私政策也会随之更新。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在 Taoify 网站、 Taoify 移动端上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问 Taoify 以便及时了解最新的隐私政策。") }}
        </p>
        <p class="content">
          {{ $t("2. 通过我们网站订购商品的协议应适用中国法律。因上述协议引起的或与上述协议相关的任何争议应由网站所有公司或网站所有人所在地有管辖权的中国法院管辖。") }}
        </p>
        <p class="content">
          {{ $t("如果您作为消费者订立协议，本条款不会影响您作为消费者的法定权利。") }}
        </p>
        <p class="content">
          {{ $t("3. 如果您在公示期间没有提出异议或在生效日后对 Taoify 网站和服务的继续使用即表示您同意并接受此修订版《隐私政策》。") }}
        </p>
        <p class="content">
          {{ $t("4. 本交易条款及其明确提及的任何文件构成您与我们之间就协议主旨所达成的完整协议，并替代您与我们之间无论口头或书面的任何在先的协议、谅解或安排。") }}
        </p>
        <p class="content">
          {{ $t("5. 如果您对本交易条款或隐私政策有任何问题，您可以登录我们的网站或通过我们的网络联系方式联系我们。") }}
        </p>
        <p class="content">
          {{ $t("我们不会收集处理个人数据。仅仅存储API服务授权信息来完成授权下的基本操作") }}
        </p>
        <p class="content">
          © Taoify 2024 
        </p>
      </v-container>
    </v-card>
  </v-app>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.titlee {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  line-height: 25px;
  margin-top: 25px;
  margin-bottom: 10px;
}
.content {
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;
  color: #6a6e79;
}
li {
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;
  color: #6a6e79;
  margin-left: 20px;
  list-style: initial;
}
</style>